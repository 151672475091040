@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

.section1 {
  background-color: #3A543A;
}

.cert-main{
  display: none;
  position: relative;
  width: 60rem;
  height: 45rem;
}
.cert-main img{
  width: 60rem;
  height: 45rem;
}
.cert-main .cert-p1{
  position: absolute;
  z-index: 99;
  font-size: 30px;
  font-weight: 600;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  top: 27.8rem;
}
.cert-main .cert-p2{
  position: absolute;
  z-index: 99;
  font-size: 15px;
  font-weight: 600;
  left: 38rem;
  top: 31.1rem;
}

.global-loader{
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000009a;
  color: white;
  font-weight: 900;
  top: 0;
  left: 0;
  font-size: 25px;
}

.Banner-Section {
  height: 500px;
  width: 100%;
  background-image: url(./imgs/06.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
}

.Banner-Section-Left {
  width: 60%;
  height: 100%;
  padding: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Banner-Section-Right {
  width: 34%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



input[type="date"]::after { 
  content: attr(placeholder);
  /* width: 50%; */
  /* color: grey; */
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
/* input[type="date"]:focus::before,
input[type="date"]:valid::before { display: none } */




.form-main {
  width: 444px;
}

.logo {
  height: 100%;
  width: 80%;
}

.OnGround {
  width: 80%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.white {
  height: 100%;
  width: 300px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.orange {
  background-color: rgb(253, 89, 29);
  margin-top: -10px;
  height: 80%;
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 10px;
}

.form-main1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-btn {
  width: 100%;
}

.form-btn button {
  width: 100%;
  background-color: white;
  color: black;
  outline: none;
  border: none;
  font-size: 20px;
  border-radius: 6px;
  padding: 8px 0px;
}

.btn-active {
  height: 60px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 20px;
  font-weight: 500;
}

.form1 {
  border-top-left-radius: 0px !important;
}

.form2 {
  border-top-right-radius: 0px !important;
}

.form-btn1 {
  margin-right: 0.5rem;
}

.form-btn2 {
  margin-left: 0.5rem;
}

.orange h1 {
  font-weight: 500;
  font-size: 25px;

}

.white h1 {

  font-weight: 700;
  font-size: 30px;
}

.form {
  width: 100%;
  height: 400px;
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form2 {
  display: none;
}

.form h2 {
  border-bottom: 3px solid black;
  width: 130px;
  text-align: center;
  margin-top: 20px;
  /* margin-left: 250px; */
}

.Inputs {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Left-Inputs,
.Right-Inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.Left-Inputs {}

.Form-Input {
  height: 37px;
  padding: 15px;
  width: 199px;
  border: none;
  background-color: #CCCCCC;
  border-radius: 8px;
  color: #838383;
  margin: 5px 10px;
  font-size: 20px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin-top: 10px;
}

.checkbox input {
  height: 15px;
  width: 15px;

}

.checkbox p {
  margin-left: 10px;
  font-size: 20px;

}

.registor {
  height: 37px;
  width: 180px;
  background-color: #000000;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  margin-top: 10px;
  /* margin-left: 240px; */
}

.section-two {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  padding: 3rem 0rem;
  padding-bottom: 5rem;
}

.section-two-left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Raleway', sans-serif;

}

.section-two-left h1 {
  margin-top: 32px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 37px;
  font-family: 'Raleway', sans-serif;

}

.section-two-left h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 26px;
  font-family: 'Raleway', sans-serif;
  text-align: center;


}

.section-two-left p {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
}

.section-two-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.section-two-left h1 {
  border-bottom: 3px solid black;
  text-align: center;
  width: 550px;
}

.Sectionthree {
  width: 100%;
  background-color: #3A543A;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sectionthree-left,
.Sectionthree-right {
  width: 50%;
  height: 100%;
  color: white;
  text-align: center;

}

.Sectionthree-left p {
  font-weight: 300;
  font-size: 30px;
  padding: 50px;
}

.Sectionthree-right {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.run {
  height: 320px;
}

.sectionfour {
  height: 100%;
  width: 100%;
  padding: 3rem 0rem;
  padding-left: 5rem;
  background-image: url(./imgs/07.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* border-top: 15px solid white;
  border-bottom: 15px solid white; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card {
  height: 200px;
  width: 200px;
  background-color: transparent !important;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.give {
  font-size: 78px;
  color: white;
  font-weight: 700;
  margin-left: 150px;
}

.borderbottm {
  height: 3px;
  width: 406px;
  background-color: white;
  margin-left: 150px;
}

.footer {
  height: 80px;
  width: 100%;
  background-color: #3A543A;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social {
  width: 100px;
  height: 80%;
  border-right: 5px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social img {
  height: 60px;
}

.contact {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.contact p {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 16px;
}

.contact img {
  height: 30px;
  margin-left: 20px;
}

.contact-no {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.days {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #3A543A;
  font-family: 'Lato', sans-serif;
}

.black-div {
  width: 100%;
  height: 135px;
  background-color: #000000;
  margin-left: 50px;
  clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.days p {
  color: #FFF;
  font-weight: 900;
  font-size: 60px;
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif;

}

.time {
  margin-left: 50px;
  font-weight: 800;
  font-size: 20px;

}

.day {
  font-size: 25px !important;
  margin-top: 30px;
  margin-left: 5px;
}

.sec5 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec51 {
  padding: 3rem 0rem;
  width: 80%;
}

.sec511 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 2rem;
}

.sec5111 {
  height: 1.3px;
  width: 30%;
  background-color: #000000;
}

.sec511 h3 {
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  margin: 0px 15px;
}

.sec512 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec5-card {
  height: 150px;
  width: 200px;
  margin: 0px 15px;
  background-color: #D9D9D9;
  border-radius: 6px;
}

.navbar {
  background-color: #3A543A;
  padding: 2.5rem 2rem;
  height: 80px;

}

.nav1 {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav11 {
  display: flex;
  align-items: center;
}

.nav11 p {
  color: white;
  margin: 0px 20px;
  font-size: 20px;
}

.nav11 .counter {
  color: white;
  font-size: 40px;
  font-weight: 600;
}

@media(max-width: 480px) {
  .nav11 .counter {
    font-size: 20px;
  }
}

.Home {
  width: 100%;
  height: 700px;
  background-image: url(./imgs/06.png);
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  overflow: hidden;
}

.home-banner {
  padding-top: 140px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.marathon-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.marathon-btns button {
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 20px;
  font-weight: 500;
}

.checks {
  width: 90%;
}

.secn-main {
  background-color: #A39976;
  padding: 2rem;
}

.flex {
  display: flex;
  flex-direction: row;
}

.sub1{
  padding: 3rem;
}
.sub11{
  margin-bottom: 2rem;
  text-align: center;
}
.sub12 input{
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .section1 {
    height: 100%;
  }

  .form-main {
    width: 350px;
  }

  .Banner-Section {
    flex-direction: column;
    height: 100%;
  }

  .black-div {
    margin-left: 0;
  }

  .registor {
    height: 37px;
    width: 333px;
    background-color: #156B00;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    margin-top: 10px;
    /* margin-left: 240px; */
  }

  .Banner-Section-Left,
  .Banner-Section-Right {
    width: 100%;
  }

  .Banner-Section-Right {
    padding: 20px;
  }

  .Banner-Section-Left {
    height: 40%;
  }

  .logo {
    height: 200px;
  }

  .white h1 {
    font-size: 20px;
  }

  .orange h1 {
    font-size: 18px;
  }

  .form {
    width: 95%;
    height: 430px;
  }

  .OnGround {
    width: 95%;
  }

  .section-two {
    height: 140vh;
    flex-direction: column;
  }

  .section-two-left {
    width: 100%;
    height: 60%;
    text-align: center;
  }

  .section-two-left p {
    font-size: 20px;
    text-align: center;


  }

  .section-two-left h1 {
    border-bottom: 3px solid black;
    text-align: center;
    width: 300px;
    font-size: 25px;
  }

  .army {
    height: 250px;
  }

  .Sectionthree {
    flex-direction: column;
    height: 100%;
  }

  .Sectionthree-left {
    width: 100%;
    height: 50%;
  }

  .Sectionthree-right {
    width: 100%;
    height: 100%;
  }

  .Sectionthree-left p {
    font-size: 22px;
  }

  .run {
    height: 100%;
    width: 100%;
  }

  .sectionfour {
    height: 100%;
    width: 100%;
    background-image: url(./imgs/07.png);
    background-size: cover;
    background-repeat: no-repeat;
    /* border-top: 15px solid white;
    border-bottom: 15px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 0;
    flex-direction: column-reverse;
  }

  .give {
    font-size: 50px;
    color: white;
    margin-left: 0px;
  }

  .card {
    margin-top: 20px;
  }

  .sec512 {
    flex-direction: column;
  }

  .sec5-card {
    margin-bottom: 1rem;
  }

  .borderbottm {
    height: 20px;
    width: 180px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0%, 86% 100%, 0% 100%);
    margin-left: 10px;
  }

  .days {
    flex-direction: column;
    padding-left: 0;
  }

  .time {
    margin-left: 0;
    margin-bottom: 13px;
  }

  .black-div {
    clip-path: none;
  }

  .Form-Input {
    width: 100%;
  }

  .form {
    height: 100%;
    padding-bottom: 2rem;
    width: 100%;
  }

  .nav11 p {
    font-size: 14px !important;
    margin: 0px 6px;
  }

  .days p {
    font-size: 30px;
  }

  .day {
    margin-top: 5px;
  }

  .black-div {
    height: 85px;
  }

  .section-two-right {
    width: 80%;
    margin-top: 3rem;
  }

  .section-two-right img {
    width: 100%;
  }

  .section-two {
    height: 100%;
  }

  .marathon-btns {
    width: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

  }

  .home-banner img {
    height: 300px;
  }

  .flex {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .home-banner img {
    height: 250px;
  }

  .nav1 {
    height: 70px;
    flex-direction: column;
  }

  .days {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1px;
    font-family: 'Lato', sans-serif;
    background-color: #3A543A;
  }

  .contact {
    flex-direction: column;
  }

  .contact p {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .home-banner img {
    height: 200px;
  }
}

.Runwithus {
  color: #000000;
  text-align: center;
  font-weight: 800;
  border-bottom: 5px solid black;
}

.modelbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-inputs {
  width: 90%;
  height: 37px;
  padding: 15px;

  border: none;
  background-color: #CCCCCC;
  border-radius: 8px;
  color: #000000;
  margin: 5px 10px;
  font-size: 20px;
}

.selections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

select {
  height: 37px;
  padding: 8px;
  width: 60%;
  border: none;
  background-color: #CCCCCC;
  border-radius: 8px;
  color: #000000;
  margin: 5px 10px;
  font-size: 20px;

}

.selections input {
  height: 37px;
  padding: 15px;
  width: 59%;
  border: none;
  background-color: #CCCCCC;
  border-radius: 8px;
  color: #000000;
  margin: 5px 10px;
  font-size: 20px;

}